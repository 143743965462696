


















































































































































































import { Component, Prop, Vue, Watch } from 'vue-property-decorator'
import { AuthService } from '@/services/auth.service'
import { IregistrationErrors } from '@/models/IregistrationErrors'
import { VueMaskDirective } from 'v-mask'
import Spinner from '@/components/spinner/spinner.vue'
import AppSelect from '@/components/core-ui/app-select/app-select'
import { store } from '@/store'
import SvgEyeVisible from '@/components/svg/svg-eye-visible.vue'
import SvgEyeHidden from '@/components/svg/svg-eye-hidden.vue'
Vue.directive('mask', VueMaskDirective)

@Component({
    components: { Spinner, AppSelect, SvgEyeVisible, SvgEyeHidden },
})
export default class RegistrationForm extends Vue {
    get regForm() {
        return {
            userName: this.userName,
            userLastName: this.userLastName,
            userPatronymic: this.userPatronymic,
            phone: this.phone.replace(/[+ ()_-]/g, ''),
            email: this.email,
            password: this.password,
            companyName: this.organizationName + ' ' + this.organizationType.name,
        }
    }

    organizationTypes = [
        { name: 'ООО' },
        { name: 'ИП' },
        { name: 'Частное лицо' },
        { name: 'Другое' },
    ]
    passwordVisibility = false
    loadingForm = false
    userName = ''
    userLastName = ''
    userPatronymic = ''
    phone = ''
    email = ''
    password = ''
    organizationName = ''
    organizationType = { name: 'OOO' }
    changeOption(payload: any) {
        this.organizationType = payload
    }
    errors: IregistrationErrors = {
        PasswordError: false,
        UserNameError: false,
    }
    auth = new AuthService()

    logIn() {
        this.$store.dispatch('auth/login')
        this.$store.dispatch('auth/toggleRegistration')
    }

    handleError(errorMessages?: string[]) {
        for (const key in this.errors) {
            this.errors[key] = false
        }
        if (errorMessages) {
            errorMessages.forEach(errorMsg => {
                this.errors[errorMsg] = true
                if (errorMsg.includes('Password')) {
                    this.errors.PasswordError = true
                } else {
                    this.errors.PasswordError = false
                }
                if (errorMsg.includes('UserName')) {
                    this.errors.UserEmailError = true
                } else {
                    this.errors.UserEmailError = false
                }
            })
        }

        const userNameRegExp = /^[a-zA-Zа-яА-я]+$/
        const userPhoneRegExp = /^\d{11}$/
        const OrganizationNameRegExpg = /^[^<>?[\]:|*']+$/

        if (!userNameRegExp.test(this.userName)) {
            this.errors.UserNameError = true
        }
        if (!userNameRegExp.test(this.userLastName)) {
            this.errors.UserLastNameError = true
        }
        if (!userNameRegExp.test(this.userPatronymic) && this.userPatronymic !== '') {
            this.errors.UserPatronymicNameError = true
        }
        if (!OrganizationNameRegExpg.test(this.organizationName)) {
            this.errors.OrganizationNameError = true
        }
        this.errors.UserPhoneError = !userPhoneRegExp.test(this.regForm.phone)
        this.$forceUpdate()
    }

    fieldValidity() {
        const errors = this.errors

        for (const key in errors) {
            if (errors.hasOwnProperty(key)) {
                if (errors[key] === true) {
                    return false
                }
            }
        }

        return true
    }
    get svgShowPasswordComponent() {
        return this.passwordVisibility ? 'svg-eye-visible' : 'svg-eye-hidden'
    }

    onSubmit(e: Event) {
        this.handleError()
        if (this.fieldValidity()) {
            this.loadingForm = true
            this.auth
                .registration(this.regForm)
                .then(res => {
                    this.loadingForm = false
                    store.dispatch('auth/login')
                    store.dispatch('display/toggleRegistration')
                })
                .catch(err => {
                    this.loadingForm = false
                    const errorMessages = (((err || []).response || []).data || []) as string
                    const errorMessagesArr = errorMessages.split(' ')

                    this.handleError(errorMessagesArr)
                })
        }
    }
    changePasswordVisibility() {
        this.passwordVisibility = !this.passwordVisibility
        const passwordInput = this.$refs.passwordInput as HTMLInputElement
        passwordInput.focus()
        if (passwordInput.type === 'password') {
            passwordInput.type = 'text'
        } else {
            passwordInput.type = 'password'
        }
        setTimeout(() => {
            passwordInput.setSelectionRange(this.password.length, this.password.length)
        }, 0)
    }
}
