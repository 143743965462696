















































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class StyleGuide extends Vue {}
