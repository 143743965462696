











import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgCar extends Vue {}
export default SvgCar
