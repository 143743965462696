





















import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgPicture extends Vue {}
export default SvgPicture
