










import { Component, Vue } from 'vue-property-decorator'
import { store } from '@/store/index'

@Component
export default class ToggleRequestCallPopup extends Vue {
    toggleRequestCallPopup() {
        store.dispatch('display/toggleRequest')
    }
}
