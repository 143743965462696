








import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgOptions extends Vue {}
export default SvgOptions
