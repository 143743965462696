


















































































import { Component, Vue, Prop } from 'vue-property-decorator'

import ActualizeUser from '@/components/shared/header/actualize-user.vue'
import SearchResults from '@/components/shared/header/search-results/search-results.vue'
import AccountInfo from '@/components/shared/header/menu-popups/account-info.vue'
import MobileMenu from '@/components/shared/header/mobile-menu.vue'

import UserAccountDesktop from '@/components/shared/header/user-account/user-account-desktop.vue'
import SearchLocationDesktop from '@/components/shared/header/menu-popups/search-location-desktop.vue'
import SearchFormDesktop from '@/components/shared/header/search-form/search-form-desktop.vue'

import SearchLocationMobile from '@/components/shared/header/menu-popups/search-location-mobile.vue'
import UserAccountMobile from '@/components/shared/header/user-account/user-account-mobile.vue'
import AuthMobile from '@/components/shared/header/menu-popups/auth-mobile.vue'
import MobileLinks from '@/components/shared/header/menu-popups/mobile-links.vue'
import SearchFormMobile from '@/components/shared/header/search-form/search-form-mobile.vue'

@Component({
    components: {
        'ts-ui-user-account-desktop': UserAccountDesktop,
        'ts-ui-user-account-mobile': UserAccountMobile,
        'ts-ui-account-info': AccountInfo,
        'ts-ui-auth-mobile': AuthMobile,
        'ts-ui-mobile-menu': MobileMenu,
        'ts-ui-search-location-mobile': SearchLocationMobile,
        'ts-ui-search-location-desktop': SearchLocationDesktop,
        'ts-ui-mobile-links': MobileLinks,
        'ts-ui-search-form-mobile': SearchFormMobile,
        'ts-ui-search-form-desktop': SearchFormDesktop,
        'ts-ui-actualize-user': ActualizeUser,
    },
})
export default class Header extends Vue {
    @Prop(String) readonly links!: string | undefined
    parsedLinks = {}
    mounted() {
        if (this.links) {
            try {
                this.parsedLinks = JSON.parse(this.links)
            } catch (e) {
                console.log('Cannot parse JSON')
            }
        }
    }
}
