










































import { Component, Vue } from 'vue-property-decorator'
import { AuthModule } from '@/store/modules/authentication.module'
import { store } from '@/store/index'
import { DisplayModule } from '../../../../store/modules/display.module'
import SvgList from '@/components/svg/svg-list.vue'

@Component({
    components: { SvgList },
})
export default class UserAccountMobile extends Vue {
    private webAppHost = process.env.VUE_APP_WEB_APP
    private webApi = process.env.VUE_APP_WEB_APP_API

    get loggedIn() {
        return AuthModule.logged
    }

    get loggingIn() {
        return AuthModule.loggingIn
    }

    get cartAggregateAmount() {
        return AuthModule.itemsAmount
    }

    get isParterRestricted() {
        return Boolean(AuthModule.isOrderingDisabled)
    }

    get avatarTimestamp() {
        if (AuthModule.profile) {
            // @ts-ignore
            return AuthModule.profile.avatarTimestamp
        }
    }

    get userId() {
        if (AuthModule.profile) {
            // @ts-ignore
            return AuthModule.profile.id
        }
    }

    get imageUrl() {
        return `${this.webApi}/api/profiles/${this.userId}/avatar?t=${this.avatarTimestamp}`
    }

    toggleUserMenu() {
        store.dispatch('display/toggleBlockShowUser')
    }

    openRestrictionModal() {
        store.dispatch('display/setContragentRestrictionModal', true)
    }

    goToCart() {
        window.location.href = `${this.webAppHost}/account/cart`
    }

    goToOrders() {
        window.location.href = `${this.webAppHost}/account/orders`
    }
}
