



















import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ClosablePopup } from '@/components/mixins/closable-popup'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'

@Component
export default class PasswordSuccessRestore extends mixins(ClosablePopup) {
    toggleSuccessRestorePopup() {
        store.dispatch('display/toggleSuccessRestore')
    }

    get showSuccessPasswordRestore() {
        return DisplayModule.showPopup.successRestore
    }

    listenEscKeyup(e: KeyboardEvent) {
        if (e.keyCode === 27 && this.showSuccessPasswordRestore) {
            store.dispatch('display/toggleSuccessRestore')
        }
    }
}
