
















import { Component, Vue } from 'vue-property-decorator'
import { store } from '@/store/index'
import { AuthModule } from '@/store/modules/authentication.module'

@Component
export default class RegBtn extends Vue {
    get loggedIn() {
        return AuthModule.logged
    }

    get loggingIn() {
        return AuthModule.status.isLoading
    }

    toggleRegistrationPopup() {
        store.dispatch('display/toggleRegistration')
    }
}
