




































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISearchResult } from '@/models/index'

@Component({})
export default class SearchResults extends Vue {
    webAppUrl = process.env.VUE_APP_WEB_APP

    @Prop({ default: () => [] }) readonly foundItems!: ISearchResult[]
    selected() {
        this.$emit('selected')
    }
}
