











import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgTruck extends Vue {}
export default SvgTruck
