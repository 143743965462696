





























import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { HeaderSearchText } from '@/components/mixins/header-search-text'
import { DisplayModule } from '@/store/modules/display.module'

@Component
export default class SearchLocation extends Vue {
    get showBlocksShowLocation() {
        return DisplayModule.blocksShow.location
    }
}
