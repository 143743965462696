














































































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ClosablePopup } from '@/components/mixins/closable-popup'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'
import { closable } from '@/directives/v-click-outside-exclude'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
Vue.directive('closable', closable)
@Component({})
export default class RequestCall extends mixins(ClosablePopup) {
    toggleRequestPopup() {
        store.dispatch('display/toggleRequest')
    }

    get showRequestCall() {
        return DisplayModule.showPopup.requestCall
    }

    listenEscKeyup(e: KeyboardEvent) {
        if (e.keyCode === 27 && this.showRequestCall) {
            store.dispatch('display/toggleRequest')
        }
    }
}
