












import { Component, Prop, Vue } from 'vue-property-decorator'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'

@Component
export default class YourCity extends Vue {
    closeYourCity() {
        store.dispatch('display/toggleYourCity')
    }

    closeYourCityAndOpenSelectCity() {
        store.dispatch('display/toggleYourCityAndOpenSelectCity')
    }

    get showYourCityBlock() {
        return DisplayModule.showBlock.yourCity
    }
    // created () {
    //   setTimeout(() => {
    //     store.dispatch('display/toggleYourCity')
    //   }, 10000)
    // }
}
