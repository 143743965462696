

























import { Component, Vue } from 'vue-property-decorator'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'
import { mixins } from 'vue-class-component'
import { HeaderSearchText } from '@/components/mixins/header-search-text'
import vClickOutside from 'v-click-outside'

Vue.use(vClickOutside)
@Component({})
export default class MobileLinks extends mixins(HeaderSearchText) {
    get showBlocksShowMenu() {
        return DisplayModule.blocksShow.menu
    }
    // example of middleware for webcomponents
    // (we cannot use e.target so we use e.composedPath[0])
    vcoMiddleware(e, el) {
        const path = e.composedPath()
        if (path[0].id === 'burger-menu' || el.contains(path[0])) {
            console.log('clicked on burger or link so no handler')
            return false
        } else {
            console.log('not clicked on burger handler starts')
            console.log(path)
            return true
        }
    }
    closeMainMenu() {
        store.dispatch('display/hideBlockShowMenu')
    }
    vcoConfig = {
        handler: this.closeMainMenu,
        middleware: this.vcoMiddleware,
    }
}
