






import { Component, Prop, Vue } from 'vue-property-decorator'
import { store } from '@/store/index'
import CookieStorage from 'cookie-storage-domain'

@Component
export default class LogOutRedirect extends Vue {
    mounted() {
        CookieStorage.setItem('shop-redirect', JSON.stringify(true))
        store.dispatch('auth/logout')
    }
}
