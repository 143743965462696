












import { Component, Prop, Vue } from 'vue-property-decorator'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'

@Component
export default class SelectCity extends Vue {
    cities = [
        'Москва',
        'Калининград',
        'Екатеринбург',
        'Санкт-Петербург',
        'Пермь',
        'Киров',
        'Киев',
        'Алматы',
    ]

    selectCity(cityName: string) {
        store.dispatch('display/toggleSelectCity')
    }

    get showSelectCity() {
        return DisplayModule.showBlock.selectCity
    }
}
