var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"news-block"},[(!_vm.small)?_c('div',{class:['news-block__large-blocks']},_vm._l((_vm.items.slice(0, 3)),function(newsItem){return _c('a',{key:newsItem.id,class:[
                'news-block__large-block',
                { 'news-block__large-block--no-image': !newsItem.previewPicture } ],style:([
                newsItem.previewPicture
                    ? { 'background-image': ("url('" + (newsItem.previewPicture) + "')") }
                    : null ]),attrs:{"href":(_vm.vueAppWebApp + "/news/" + (newsItem.id))}},[_c('div',{staticClass:"news-block__large-block-shadow"},[_c('div',{staticClass:"news-block__large-block-content"},[_c('div',{staticClass:"news-block__large-block-title"},[_vm._v("\n                        "+_vm._s(newsItem.title)+"\n                    ")]),_c('div',{staticClass:"news-block__large-block-footer"},[_c('span',{staticClass:"news-block__large-block-category"},[_vm._v("\n                            "+_vm._s(newsItem.badgeName || 'Новости')+"\n                        ")]),_vm._v("\n                        •\n                        "),_c('span',[_vm._v(_vm._s(newsItem.date))])])])])])}),0):_vm._e(),_c('div',{staticClass:"news-block__small-blocks"},_vm._l((_vm.smallBlockItems),function(newsItem){return _c('a',{key:newsItem.id,class:[
                'news-block__small-block',
                { 'news-block__small-block--no-image': !newsItem.previewPicture } ],style:([
                newsItem.previewPicture
                    ? { 'background-image': ("url('" + (newsItem.previewPicture) + "')") }
                    : null ]),attrs:{"href":(_vm.vueAppWebApp + "/news/" + (newsItem.id))}},[_c('div',{staticClass:"news-block__small-block-shadow"},[_c('div',{staticClass:"news-block__large-block-content"},[_c('div',{staticClass:"news-block__small-block-title"},[_vm._v("\n                        "+_vm._s(newsItem.title)+"\n                    ")]),_c('div',{staticClass:"news-block__small-block-footer"},[_c('span',{staticClass:"news-block__small-block-category"},[_vm._v("\n                            "+_vm._s(newsItem.badgeName || 'Новости')+"\n                        ")]),_vm._v("\n                        •\n                        "),_c('span',[_vm._v(_vm._s(newsItem.date))])])])])])}),0)])}
var staticRenderFns = []

export { render, staticRenderFns }