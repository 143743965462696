





























import { Component, Prop, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ClosablePopup } from '@/components/mixins/closable-popup'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'

@Component
export default class LogIn extends mixins(ClosablePopup) {
    logInForm = {
        email: '',
        password: '',
    }

    foreignPc = false

    toggleForeignPc() {
        this.foreignPc = !this.foreignPc
    }

    toggleLogInPopup() {
        store.dispatch('display/toggleLogIn')
    }

    closeLogInAndOpenRestration() {
        store.dispatch('display/closeLogInAndOpenRegistration')
    }

    closeLogInAndOpenRestorePassword() {
        store.dispatch('display/closeLogInAndOpenRestore')
    }

    get modalIsOpened() {
        return false // DisplayModule.showPopup.logIn
    }

    login(e: Event) {
        // module is injected in header
        store.dispatch('auth/login')
        store.dispatch('display/toggleLogIn')
    }

    listenEscKeyup(e: KeyboardEvent) {
        if (e.keyCode === 27 && this.modalIsOpened) {
            store.dispatch('display/toggleLogIn')
        }
    }
}
