














import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgList extends Vue {}
export default SvgList
