



































import { Component, Vue } from 'vue-property-decorator'
import { mixins } from 'vue-class-component'
import { ClosablePopup } from '@/components/mixins/closable-popup'
import { store } from '@/store/index'
import { DisplayModule } from '@/store/modules/display.module'

@Component
export default class PasswordRestore extends mixins(ClosablePopup) {
    email = ''

    restorePassword(e: Event) {
        e.preventDefault()
        store.dispatch('display/closeLogInAndOpenSuccessRegistration')
    }

    toggleRestorePopup() {
        store.dispatch('display/toggleRestore')
    }

    get showPasswordRestore() {
        return DisplayModule.showPopup.restorePassword
    }

    listenEscKeyup(e: KeyboardEvent) {
        if (e.keyCode === 27 && this.showPasswordRestore) {
            store.dispatch('display/toggleRestore')
        }
    }
}
