











import { Component } from 'vue-property-decorator'
import Vue from 'vue'
@Component({})
class SvgCross extends Vue {}
export default SvgCross
