















































































import { Component, Prop, Vue } from 'vue-property-decorator'
// import { Carousel, Slide } from 'vue-carousel'
import RegistrationForm from '@/components/authorization/registration/registration-form/registration-form.vue'
import { mixins } from 'vue-class-component'
import { ClosablePopup } from '@/components/mixins/closable-popup'
import { DisplayModule } from '@/store/modules/display.module'
import { store } from '@/store/index'

@Component({
    components: {
        // Carousel,
        // Slide,
        'ts-ui-registration-form': RegistrationForm,
    },
})
export default class Registration extends mixins(ClosablePopup) {
    toggleRegistrationPopup() {
        store.dispatch('display/toggleRegistration')
    }

    get showRegistration() {
        return DisplayModule.showPopup.registration
    }

    listenEscKeyup(e: KeyboardEvent) {
        if (e.keyCode === 27 && this.showRegistration) {
            store.dispatch('display/toggleRegistration')
        }
    }
}
