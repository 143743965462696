

































































import { Component, Vue, Prop } from 'vue-property-decorator'
import { DisplayModule } from '@/store/modules/display.module'
import { AuthModule } from '@/store/modules/authentication.module'
import { store } from '@/store'
import { currencyFormatRu } from '@/filters'
import { closable } from '@/directives/v-click-outside-exclude'
import vClickOutside from 'v-click-outside'
import { IPartner } from '../../../../models'

Vue.filter('currencyFormatRu', currencyFormatRu)
// Vue.use(vClickOutside)
Vue.directive('vClickOutside', vClickOutside)
Vue.directive('closable', closable)
@Component
export default class AccountInfoMobile extends Vue {
    private webAppHost = process.env.VUE_APP_WEB_APP
    oldSiteUrl = process.env.VUE_APP_OLD_SITE
    vcoConfig = {
        handler: this.closeUserMenu,
        middleware: this.vcoMiddleware,
    }

    get contrAgent() {
        return AuthModule.contragent
    }

    get singleContrAgent() {
        return AuthModule.contragentIsSingle
    }
    get loggedIn() {
        return AuthModule.logged
    }

    get showBlocksShowMenu() {
        return DisplayModule.blocksShow.menu
    }

    get showBlocksShowUser() {
        return DisplayModule.blocksShow.user
    }

    get showBlocksShowLocation() {
        return DisplayModule.blocksShow.location
    }

    logout() {
        store.dispatch('auth/logout')
    }

    closeUserMenu() {
        // @ts-ignore
        if (this.$el.offsetWidth > 0 && this.$el.offsetHeight > 0) {
            store.dispatch('display/hideBlockShowUser')
        }
    }
    toggleContrAgent() {
        store.dispatch('display/toggleContrAgentModal')
    }

    get userName() {
        return AuthModule.name
    }

    get partnerProfile(): IPartner | null {
        return AuthModule.partnerProfile
    }

    get partnerBalance() {
        if (AuthModule.partnerProfile) {
            return AuthModule.partnerProfile.balance
        }

        return undefined
    }

    get cartAggregateAmount() {
        return AuthModule.itemsAmount
    }

    get restrictionMessage() {
        if (this.isCreditOverflow && this.isDurationOverflow) {
            return 'Превышен срок и сумма кредита'
        } else if (this.isCreditOverflow) {
            return 'Превышена сумма кредита'
        } else if (this.isDurationOverflow) {
            return 'Превышен срок кредита'
        }
    }
    get isCreditOverflow() {
        if (AuthModule.partnerProfile && AuthModule.partnerProfile.restrictions) {
            return AuthModule.partnerProfile.restrictions.isCreditOverflow
        }
    }
    get isDurationOverflow() {
        if (AuthModule.partnerProfile && AuthModule.partnerProfile.restrictions) {
            return AuthModule.partnerProfile.restrictions.isDurationOverflow
        }
    }
    get isOrderingDisabled() {
        return AuthModule.isOrderingDisabled
    }
    get favouritesAmount() {
        return AuthModule.favouritesAmount
    }
    vcoMiddleware(e, el) {
        const path = e.composedPath()
        if (path.some(x => x.id === 'user-menu-desktop') || el.contains(path[0])) {
            return false
        } else {
            return true
        }
    }
}
