



































































import { Component, Vue, Watch } from 'vue-property-decorator'
import { AuthModule } from '@/store/modules/authentication.module'
import { store } from '@/store/index'
import axios from '@/services/axios.service.ts'
import { ICartInfo } from '@/models'
import VueTooltip from 'v-tooltip'
import SvgList from '@/components/svg/svg-list.vue'

Vue.use(VueTooltip)
// Vue.directive(VueTooltip)
@Component({
    components: { SvgList },
    directives: { VueTooltip },
})
export default class UserAccountDesktop extends Vue {
    private webAppHost = process.env.VUE_APP_WEB_APP
    private webApi = process.env.VUE_APP_WEB_APP_API

    cartInfo: ICartInfo = {}
    get cartAggregateAmount() {
        return AuthModule.itemsAmount
    }
    get loggedIn() {
        return AuthModule.logged
    }
    get loggingIn() {
        return AuthModule.status.isLoading
    }
    get avatarTimestamp() {
        if (AuthModule.profile) {
            // @ts-ignore
            return AuthModule.profile.avatarTimestamp
        }
    }

    get userId() {
        if (AuthModule.profile) {
            // @ts-ignore
            return AuthModule.profile.id
        }
    }

    get userName() {
        return AuthModule.name.length > 10
            ? AuthModule.name.substring(0, 12) + '...'
            : AuthModule.name
    }

    get contrAgent() {
        if (AuthModule.contragent) {
            // @ts-ignore
            return AuthModule.contragent.name || ''
        } else {
            return ''
        }
    }
    get singleContrAgent() {
        return AuthModule.contragentIsSingle
    }

    get imageUrl() {
        return `${this.webApi}/api/profiles/${this.userId}/avatar?t=${this.avatarTimestamp}`
    }

    get isParterRestricted() {
        return Boolean(AuthModule.isOrderingDisabled)
    }

    async mounted() {
        if (this.loggedIn) {
            store.dispatch('auth/setInitialItemsAmount')
        }
    }

    toggleContrAgent() {
        store.dispatch('display/toggleContrAgentModal')
    }

    logIn() {
        store.dispatch('auth/login')
    }

    toggleRegistrationPopup() {
        store.dispatch('display/toggleRegistration')
    }

    toggleUserMenu() {
        setTimeout(() => {
            store.dispatch('display/toggleBlockShowUser')
        })
    }

    openRestrictionModal() {
        store.dispatch('display/setContragentRestrictionModal', true)
    }
}
