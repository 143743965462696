







import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgPlus extends Vue {}
export default SvgPlus
