








import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgOrder extends Vue {}
export default SvgOrder
