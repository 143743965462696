import { render, staticRenderFns } from "./svg-pencil.vue?vue&type=template&id=2359ce66&shadow"
import script from "./svg-pencil.vue?vue&type=script&lang=ts&shadow"
export * from "./svg-pencil.vue?vue&type=script&lang=ts&shadow"
function injectStyles (context) {
  
  
}

/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports