



























































































































import { Component, Vue } from 'vue-property-decorator'
import { store } from '@/store/index'
import ToggleRequestCallPopup from '@/components/shared/footer/toggle-register-call-popup.vue'

@Component({
    components: {
        'ts-ui-toggle-register-call-popup': ToggleRequestCallPopup,
    },
})
export default class Footer extends Vue {
    oldSiteUrl = process.env.VUE_APP_OLD_SITE
    oldSiteName = process.env.VUE_APP_OLD_SITE!.replace(/https?:\/\//, '')
    toggleRequestCallPopup() {
        store.dispatch('display/toggleRequest')
    }
}
