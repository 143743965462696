import { render, staticRenderFns } from "./search-results.vue?vue&type=template&id=4c8467fe&"
import script from "./search-results.vue?vue&type=script&lang=ts&"
export * from "./search-results.vue?vue&type=script&lang=ts&"
function injectStyles (context) {
  
  var style0 = require("./search-results.vue?vue&type=style&index=0&lang=scss&")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports