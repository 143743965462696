import { render, staticRenderFns } from "./user-account-desktop.vue?vue&type=template&id=18cbffd0&shadow"
import script from "./user-account-desktop.vue?vue&type=script&lang=ts&shadow"
export * from "./user-account-desktop.vue?vue&type=script&lang=ts&shadow"
function injectStyles (context) {
  
  var style0 = require("./user-account-desktop.vue?vue&type=style&index=0&lang=scss&shadow")
if (style0.__inject__) style0.__inject__(context)

}

/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  injectStyles,
  null,
  null
  ,true
)

export default component.exports