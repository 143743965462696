
























































import { Component, Vue, Prop } from 'vue-property-decorator'
import { ISearchResult } from '@/models/index'

@Component({})
export default class SearchResultsByVin extends Vue {
    @Prop({ default: () => [] }) readonly foundItems!: ISearchResult[]
}
