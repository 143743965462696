


































import { Vue, Component } from 'vue-property-decorator'
@Component({})
class SvgInstagram extends Vue {}
export default SvgInstagram
